<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    {{ id ? '修改' : '新增' }}辅助工具
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="auxiliaryInfo" :rules="rules" ref="auxiliaryInfo">
                    <!-- <el-form-item label="编号" :label-width="formLabelWidth" prop="id" required v-if="state"
                        class="input-lenght">
                        <el-input v-model="auxiliaryInfo.id" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item> -->
                    <el-form-item label="条码/标签" :label-width="formLabelWidth" prop="auxCode" class="input-lenght">
                        <el-input v-model="auxiliaryInfo.auxCode" autocomplete="off" placeholder="请录入辅助工具条码/标签"></el-input>
                    </el-form-item>
                    <el-form-item label="工具分类" :label-width="formLabelWidth" prop="classCode">
                        <el-select placeholder="请选择工器具分类名称" v-model="auxiliaryInfo.classCode">
                            <el-option v-for="(item, index) in toolClassList" :key="index" :label="item.className"
                                :value="item.classCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工具名称" :label-width="formLabelWidth" prop="auxName" class="input-lenght">
                        <el-input v-model="auxiliaryInfo.auxName" autocomplete="off" placeholder="请输入工具名称"></el-input>
                    </el-form-item>
                    <el-form-item label="规格型号" :label-width="formLabelWidth" prop="auxType" class="input-lenght">
                        <el-input v-model="auxiliaryInfo.auxType" autocomplete="off" placeholder="请输入规格型号"></el-input>
                    </el-form-item>
                    <el-form-item label="工具编号" :label-width="formLabelWidth" prop="auxNum" class="input-lenght">
                        <el-input v-model="auxiliaryInfo.auxNum" autocomplete="off" placeholder="请输入工具编号"></el-input>
                    </el-form-item>
                    <el-form-item label="保质期/有效期(年)" :label-width="formLabelWidth" prop="expirationDate">
                        <el-input type="number" v-model="auxiliaryInfo.expirationDate" autocomplete="off"
                            style="width: 220px;" placeholder="请输入保质期/有效期(年)"></el-input>
                    </el-form-item>
                    <el-form-item label="出厂日期" :label-width="formLabelWidth" prop="produceDate">
                        <el-date-picker v-model="auxiliaryInfo.produceDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择出厂日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="上次试验日期" :label-width="formLabelWidth" prop="lastTestDate">
                        <el-date-picker v-model="auxiliaryInfo.lastTestDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择上次试验日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="下次试验日期" :label-width="formLabelWidth" prop="nextTestDate">
                        <el-date-picker v-model="auxiliaryInfo.nextTestDate" format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd" type="date" placeholder="选择下次试验日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="所在库房" :label-width="formLabelWidth" prop="whCode">
                        <el-select placeholder="请选择库房" v-model="auxiliaryInfo.whCode">
                            <el-option v-for="item in houseList" :key="item.code" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="存放位置" :label-width="formLabelWidth" prop="location" class="input-lenght">
                        <el-input v-model="auxiliaryInfo.location" autocomplete="off" placeholder="请输入存放位置"></el-input>
                    </el-form-item>
                    <el-form-item label="详情图片" :label-width="formLabelWidth" prop="detailsImg">
                        <div class="bannerbox">
                            <div class="banners" v-for="(item, index) in imgsList" :key="index">
                                <i class="el-icon-error cha" @click="handleDel(item, index)" style="font-size:30px"></i>
                                <uploadImg :imageUrl="item.imageName" @imgVal="imgVal($event, item)" :type="item.type">
                                </uploadImg>
                            </div>
                            <div class="upload1" v-if="imageView">
                                <el-upload class="upload-demo1" action :show-file-list="false" multiple drag
                                    :http-request="handleUploadFile">
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">点击上传
                                        <!-- <div
                            class="el-upload__tip"
                            slot="tip"
                          >请上传图片</div> -->
                                    </div>
                                </el-upload>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="配置标准" :label-width="formLabelWidth" prop="auxPzbz" class="input-lenght">
                        <el-input v-model="auxiliaryInfo.auxPzbz" autocomplete="off" placeholder="请输入配置标准"></el-input>
                    </el-form-item>
                    <el-form-item label="现有配置" :label-width="formLabelWidth" prop="auxXypz" class="input-lenght">
                        <el-input v-model="auxiliaryInfo.auxXypz" autocomplete="off" placeholder="请输入现有配置"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" :label-width="formLabelWidth" prop="remark" class="input-lenght">
                        <el-input v-model="auxiliaryInfo.remark" autocomplete="off" placeholder="请输入备注"></el-input>
                    </el-form-item>
                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button class="btn" :disabled="submitFlag" @click="submitForm()">提交</el-button>
                        <el-button @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-card>
    </div>
</template>

<script>
import uploadImg from '@/components/UploadImg/index'
import { AuxilInfoRule } from '@/utils/vaildate.js'
import { getAssistClassAll } from '@/api/assistClass.js'
import { uploadFile } from '@/api/file'
import { queryAuxiliaryInfo, addAuxiliaryInfo, updateAuxiliaryInfo } from '@/api/auxilInfo.js'
import { getHouseAll } from '@/api/house.js'
export default {
    components: {
        uploadImg
    },
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            toolClassList: [],
            houseList: [],
            auxiliaryInfo: {
                id: null,
                auxCode: null,
                classCode: null,
                auxName: null,
                auxType: null,
                auxNum: null,
                expirationDate: null,
                produceDate: null,
                lastTestDate: null,
                nextTestDate: null,
                auxPzbz: null,
                auxXypz: null,
                whCode: null,
                location: null,
                detailsImg: null,
                parma1: null,
                parma2: null,
                remark: null
            },
            formLabelWidth: "150px",
            rules: AuxilInfoRule,
            imgsList: [],
            imageView: true,
            path: process.env.VUE_APP_URL + this.UploadPath,
            submitFlag: false,// 是否提交中,
            state: this.id ? true : false

        }
    },
    mounted() {
        this.id && this.loadTeamToolInfo()
        this.loadToolClass()
        this.loadHouses()

    },
    methods: {

        async loadHouses() {
            await getHouseAll().then(res => {
                if (res.code === '000000') {
                    this.houseList = res.t
                }
            })
        },


        async loadToolClass() {
            await getAssistClassAll().then(res => {
                if (res.code === '000000') {
                    this.toolClassList = res.t
                }
            })
        },

        loadTeamToolInfo() {
            queryAuxiliaryInfo(this.id).then(res => {
                if (res.code === '000000') {
                    this.auxiliaryInfo = res.t
                    if (this.auxiliaryInfo.detailsImg != null && this.auxiliaryInfo.detailsImg != '') {
                        const list2 = this.auxiliaryInfo.detailsImg.split(";")
                        list2.forEach(item => {
                            if (item != '' && item != null) {
                                const obj = {
                                    imageName: item
                                }
                                this.imgsList.push(obj)
                            }
                        })
                        if (this.imgsList.length > 3) {
                            this.imageView = false;
                        }

                    }
                }
            })
        },


        submitForm() {
            this.$refs.auxiliaryInfo.validate((valid) => {
                if (valid) {
                    this.submitFlag = true
                    this.auxiliaryInfo.detailsImg = '';
                    this.imgsList.forEach(item => {
                        this.auxiliaryInfo.detailsImg += (item.imageName + ";")
                    })
                    if (this.id) {
                        updateAuxiliaryInfo(this.auxiliaryInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    } else {
                        addAuxiliaryInfo(this.auxiliaryInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        handleReset() {
            this.$refs.auxiliaryInfo.resetFields()
        },


        handleUploadFile(file) {
            const formData = new FormData()
            formData.append('file', file.file)

            uploadFile(formData).then(res => {
                if (res.code === '000000') {
                    this.imageUrl = res.data.importFileName
                    const obj = {
                        imageName: res.data.importFileName
                    }
                    this.imgsList.push(obj)
                    // this.$emit('imgVal', res.data.path)
                    this.auxiliaryInfo.detailsImg = '';
                    this.imgsList.forEach(item => {
                        this.auxiliaryInfo.detailsImg += (item.imageName + ";")
                    })
                    if (this.imgsList.length > 3) {
                        this.imageView = false;
                    }

                }
            })
        },
        handleDel(item, index) {
            this.imgsList.splice(index, 1)
            this.imageView = true
        },
        // 图片上传后地址
        imgVal(val, item) {
            item.imageName = val
        },

    }
}

</script>


<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 80%;
    }

    .df {
        text-align: left;
    }

    .input-lenght {
        width: 500px;
    }

    .upload1 {
        width: 360px;
        height: 240px;
        margin-right: 20px;
        margin-bottom: 20px;

        .el-upload-dragger,
        .el-upload--text,
        .upload-demo1 {
            width: 100%;
            height: 100%;
        }

        .el-upload-dragger .el-icon-upload {
            margin-top: 60px;
        }

        .el-upload__text {
            margin-top: 20px;
        }
    }

    .bannerbox {
        display: flex;
        flex-wrap: wrap;
    }

    .banners {
        width: 360px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;

        .cha {
            position: absolute;
            right: -10px;
            top: -10px;
            cursor: pointer;
            z-index: 2;
        }

        .el-upload__tip {
            margin-top: 7px;
        }

        .el-upload {
            width: 100%;
        }

        .el-upload-dragger {
            border: none;
            border-radius: 0;
        }
    }

    .avatar {
        width: 100%;
        height: 100%;
    }

    .btns {
        margin-top: 50px;
    }

    .upload-demo {
        width: 360px;
        height: 200px;
    }

    .el-icon-error {
        font-size: 20px;
    }

    .el-icon-error:hover {
        color: red;
    }


}</style>